import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import OAuthBar from '../../components/auth/OAuthBar';
import { KTRLogo } from '../../components/shared/ktr';
import useAdParams from '../../hooks/useAdParams';
import { useAuth } from '../../hooks/useAuth';

type LocationProps = {
  state: {
    from: Location;
  };
};

export default function SignUp() {
  let navigate = useNavigate();
  let { isAuthenticated, setAccessToken, setRefreshToken, user } = useAuth();

  let { adParams } = useAdParams();
  let location = useLocation() as LocationProps;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [tos, setTos] = useState(false);
  const [tosError, setTosError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !success) {
      navigate('/');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user && success) {
      api.user.addUserInsight(user.id, 'AD_PARAMS', adParams);
      const { language, platform, userAgent } = navigator;
      api.user.addUserInsight(user.id, 'BROWSER_PARAMS', {
        language,
        platform,
        userAgent,
      });
      navigate('/');
    }
  }, [user, success, adParams]);

  async function registerEmail() {
    try {
      if (!tos) {
        setTosError(true);
        return;
      }
      let { status, data } = await api.auth.signUp({
        email,
        password,
        firstname,
        lastname,
      });
      if (status === 201 && data.result) {
        toast.success('SignUp successful');
        setSuccess(true);
        setAccessToken(data.result.access_token);
        setRefreshToken(data.result.refresh_token);
        if (window.gtag) {
          window.gtag('event', 'conversion', {
            send_to: 'AW-10926362464/oMOtCJ-A7cUDEOCejNoo',
          });
          console.log('Conversion');
        }
        // if (location.state && location.state.from) {
        //   navigate("/login", { state: { from: location.state.from } });
        // } else {
        //   navigate("/login");
        // }
      } else {
        toast.error('Error on signup');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error('Something really went wrong!');
      }
    }
  }

  return (
    <div className='mt-20 flex flex-grow'>
      <div className='flex w-full flex-col items-center'>
        <div className='flex flex-col items-center'>
          <div className='h-40 w-40'>
            <KTRLogo />
          </div>
          <h2 className='mt-6 text-center text-3xl font-extrabold'>
            Create a new account
          </h2>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Or{' '}
            <Link
              className='font-medium text-indigo-600 hover:text-indigo-500'
              to='/login'
            >
              login to an existing one
            </Link>
          </p>
        </div>
        <div className='mt-8 w-full max-w-md rounded-md bg-white px-10 py-8 pt-5 shadow'>
          {/* {inviteId && targetTeam && (
            <>
              <div className="shadow px-5 py-3 text-center bg-emerald-500 text-white  rounded-md w-full ">
                <p>You have been invited to join </p>
                <span className="font-bold">{targetTeam}</span>
              </div>
              <div className="flex justify-center mt-2">
                <small className="text-gray-600">
                  Create an account below to get access
                </small>
              </div>
            </>
          )}
          {inviteId && targetTeam === null && (
            <>
              <div className="shadow px-5 py-3 text-center bg-rose-600 text-white  rounded-md w-full ">
                <p className="font-semibold">
                  The provided invite code is invalid{" "}
                </p>
              </div>
            </>
          )} */}
          <div className='mt-6 space-y-5'>
            <div>
              <label
                htmlFor='email-address'
                className='block text-sm font-medium text-gray-700'
              >
                Email address
              </label>
              <div className='mt-1'>
                <input
                  id='email-address'
                  name='email'
                  type='email'
                  autoComplete='email'
                  required
                  className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 sm:text-sm'
                  placeholder=''
                  onChange={(email) => setEmail(email.target.value)}
                  defaultValue={email}
                  // disabled={inviteId !== null && email !== ""}
                />
              </div>
            </div>
            {/* <div>
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                Artist Name
              </label>
              <div className="mt-1">
                <input
                  id="first-name"
                  name="firstname"
                  type="text"
                  // autoComplete="firstname"
                  required
                  className="w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder=""
                  onChange={(firstname) => setFirstname(firstname.target.value)}
                  defaultValue={firstname}
                />
              </div>
            </div> */}
            {/* <div>
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                Lastname
              </label>
              <div className="mt-1">
                <input
                  id="last-name"
                  name="lastname"
                  type="text"
                  autoComplete="lastname"
                  required
                  className="w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder=""
                  onChange={(lastname) => setLastname(lastname.target.value)}
                  defaultValue={lastname}
                />
              </div>
            </div> */}
            <div>
              <label
                htmlFor='password'
                className='block text-sm font-medium text-gray-700'
              >
                Password
              </label>
              <div className='mt-1'>
                <input
                  id='password'
                  name='password'
                  type='password'
                  autoComplete='current-password'
                  required
                  className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  placeholder=''
                  onChange={(password) => setPassword(password.target.value)}
                  defaultValue={password}
                />
              </div>
            </div>
            <div className='flex items-center'>
              <input
                id='tos'
                name='tos'
                type='checkbox'
                className={`h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 ${
                  tosError ? 'border-2 border-red-700 ' : ''
                }`}
                onChange={() => {
                  setTos(!tos);
                  if (tosError) {
                    if (!tos) {
                      setTosError(false);
                    }
                  }
                }}
              />
              <label
                htmlFor='tos'
                className={`ml-2 block text-sm ${
                  tosError ? 'text-red-800' : ''
                }`}
              >
                Accept the{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  className={`font-medium text-indigo-600 hover:text-indigo-500 ${
                    tosError ? 'text-red-900 hover:text-red-900' : ''
                  }`}
                  href='https://ktr.studio/tos'
                >
                  Terms of Service
                </a>
              </label>
            </div>
            {tosError && (
              <div className='flex items-center text-xs text-red-800'>
                Please accept our Terms of Service to proceed
              </div>
            )}
          </div>

          <div className='mt-6'>
            <button
              type='submit'
              className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              onClick={() => registerEmail()}
            >
              Sign up
            </button>
          </div>
          <div className='relative my-8'>
            <div className='absolute inset-0 flex items-center'>
              <div className='w-full border-t border-gray-300' />
            </div>
            <div className='relative flex justify-center text-sm'>
              <span className='bg-white px-2 text-gray-500'>
                Or continue with
              </span>
            </div>
          </div>
          <OAuthBar />
        </div>
      </div>
    </div>
  );
}
