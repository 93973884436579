import { Combobox } from '@headlessui/react';
import {
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { FaMusic, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router';
import api from '../../../api/';
import { IProject } from '../../../api/services/project.service';
import { ITrack } from '../../../api/services/track.service';
import GenericDialog from '../../shared/GenericDialog';
import Select from '../../shared/Select';
import { useAlbumStudioState } from '../albumState';
import { useTracks } from './useTracks';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function TrackListDialog({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { id } = useParams();
  const { activeAlbumTracks, refreshData } = useAlbumStudioState();
  const {
    resetFilters,
    projects,
    setQuery,
    projectFilter,
    trackList,
    BPMFilter,
    catalogueFilter,
    keynoteFilter,
    artistFilter,
    scaleTypeFilter,
    catalogues,
    keynotes,
    artists,
    scaleTypes,
    filteredTracks,
    setProjectFilter,
    setBPMFilter,
    setCatalogueFilter,
    setKeynoteFilter,
    setArtistFilter,
    setScaleTypeFilter,
    BPMOPtions,
    query,
  } = useTracks();

  useEffect(() => {
    if (isOpen) {
      resetFilters();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      <GenericDialog
        className='!max-w-2xl'
        onUpperRightClick={() => setIsOpen(false)}
        upperRightIcon={
          <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
        }
        header='Track Library'
        headerIcon={<FaMusic size={20} />}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <Combobox
          onChange={(track: ITrack) => {
            if (!id || !track.id) return;
            if (activeAlbumTracks.some((t) => t.id === track.id)) {
              return api.album
                .deleteTrackFromAlbum(id, track.id)
                .then(() => {
                  refreshData('activeProjectTracks');
                  refreshData('activeFiles');
                })
                .catch(console.error);
            }
            api.album
              .addTracktoAlbum(id, {
                trackId: track.id,
                trackCollectionId: id,
                createdAt: new Date().toISOString(),
              })
              .then(() => {
                refreshData('activeProjectTracks');
                refreshData('activeFiles');
              })
              .catch(console.error);
          }}
          value={null}
        >
          <div className='relative'>
            <MagnifyingGlassIcon
              className='pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
            <Combobox.Input
              autoComplete='off'
              className='h-12 w-full rounded-md border bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 dark:text-white sm:text-sm'
              placeholder='Search...'
              onChange={(event) => setQuery(event.target.value)}
              data-testid='TrackListDialog-input'
            />
          </div>
          {!!trackList.length && (
            <div>
              <div className='!mt-2 grid grid-cols-3 gap-2'>
                <Select<IProject>
                  options={projects}
                  placeholder='Filter by submission'
                  selected={projectFilter}
                  setSelected={(v: IProject | undefined) =>
                    setProjectFilter((p) =>
                      p && p.id === v?.id ? undefined : v
                    )
                  }
                  renderLabel={(v: IProject) => v.name}
                />
                {(!!artists.length || artistFilter) && (
                  <Select<string | undefined>
                    options={artists}
                    placeholder='Filter by artist'
                    selected={artistFilter}
                    setSelected={(v: string | undefined) =>
                      setArtistFilter((p) => (p && p === v ? '' : v))
                    }
                    renderLabel={(v) => v || ''}
                  />
                )}
                {(!!BPMOPtions.length || BPMFilter) && (
                  <Select<number | undefined>
                    options={BPMOPtions}
                    placeholder='Filter by bpm'
                    selected={BPMFilter}
                    setSelected={(v: number | undefined) =>
                      setBPMFilter((p) => (p && p === v ? undefined : v))
                    }
                    renderLabel={(v: number | undefined) => `${v} BPM`}
                  />
                )}
                {(!!catalogues.length || catalogueFilter) && (
                  <Select<string | undefined>
                    options={catalogues}
                    placeholder='Filter by catalogue'
                    selected={catalogueFilter}
                    setSelected={(v: string | undefined) =>
                      setCatalogueFilter((p) => (p && p === v ? '' : v))
                    }
                    renderLabel={(v) => v || ''}
                  />
                )}
                {(!!keynotes.length || keynoteFilter) && (
                  <Select<string | undefined>
                    options={keynotes}
                    placeholder='Filter by keynote '
                    selected={keynoteFilter}
                    setSelected={(v: string | undefined) =>
                      setKeynoteFilter((p) => (p && p === v ? '' : v))
                    }
                    renderLabel={(v) => v || ''}
                  />
                )}
                {(!!scaleTypes.length || scaleTypeFilter) && (
                  <Select<string | undefined>
                    options={scaleTypes}
                    placeholder='Filter by scale type'
                    selected={scaleTypeFilter}
                    setSelected={(v: string | undefined) =>
                      setScaleTypeFilter((p) => (p && p === v ? '' : v))
                    }
                    renderLabel={(v) => v || ''}
                  />
                )}
              </div>
              <div className='flex items-center justify-end pt-2'>
                <button
                  onClick={resetFilters}
                  className='px-2 text-start text-gray-700 transition-colors hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-500'
                >
                  Reset filters
                </button>
              </div>
            </div>
          )}

          <Combobox.Options
            static
            className='h-64 max-h-80 scroll-py-2 divide-y divide-gray-100 overflow-y-auto rounded-md border'
          >
            <li className='p-2'>
              <ul className='text-sm text-gray-700'>
                {filteredTracks.map((track) => (
                  <Combobox.Option
                    key={track.id}
                    value={track}
                    className={({ active }) =>
                      classNames(
                        'flex cursor-default select-none items-center rounded-md border-b px-3 py-3 transition-colors dark:text-white',
                        active ? 'cursor-pointer bg-indigo-600 text-white' : '',
                        activeAlbumTracks.some((t) => t.id === track.id)
                          ? 'bg-indigo-500 text-white'
                          : ''
                      )
                    }
                  >
                    {({ active }) => {
                      return (
                        <>
                          <FaMusic
                            className={classNames(
                              'h-6 w-6 flex-none transition-colors',
                              active ? 'text-white' : 'text-gray-400',
                              activeAlbumTracks.some((t) => t.id === track.id)
                                ? 'text-white'
                                : ''
                            )}
                            aria-hidden='true'
                          />
                          <span className='ml-3 flex-auto truncate'>
                            {track.name}
                          </span>
                          {active && (
                            <span className='ml-3 flex-none text-indigo-100'>
                              {activeAlbumTracks.some((t) => t.id === track.id)
                                ? 'Remove from Album'
                                : 'Add to Album'}
                            </span>
                          )}
                        </>
                      );
                    }}
                  </Combobox.Option>
                ))}
              </ul>
            </li>
            {query !== '' && trackList.length === 0 && (
              <div className='py-12 px-6 text-center sm:px-14'>
                <QuestionMarkCircleIcon
                  className='mx-auto h-6 w-6 text-gray-400'
                  aria-hidden='true'
                />
                <p className='mt-4 text-sm text-gray-900 dark:text-white'>
                  We couldn't find any anything with that term. Please try
                  again.
                </p>
              </div>
            )}
          </Combobox.Options>
        </Combobox>
      </GenericDialog>
    </>
  );
}
