// components/StatsCard.js
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { Tooltip } from 'flowbite-react';
import { FaSpinner } from 'react-icons/fa';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function StatsCard({ stat }: { stat: any }) {
  return (
    <div
      className='relative overflow-hidden rounded-lg bg-white px-4 pt-4 pb-4 shadow hover:cursor-pointer hover:bg-slate-50 dark:bg-slate-900 hover:dark:bg-slate-800 sm:px-4 sm:pt-4 sm:pb-4'
      onClick={stat.onClick}
    >
      <dt>
        <div className='absolute rounded-md bg-indigo-500 p-3'>
          <stat.icon className='h-6 w-6 text-white' aria-hidden='true' />
        </div>
        <p className='ml-16 truncate text-sm font-medium text-slate-500 dark:text-slate-200'>
          {stat.name}
        </p>
      </dt>
      <dd className='ml-16 flex items-baseline'>
        <p className='text-2xl font-semibold text-slate-900 dark:text-slate-50'>
          {stat.stat ? stat.stat : '-'}
        </p>
        <p
          className={classNames(
            stat.changeType === 'increase'
              ? 'text-green-600 dark:text-green-500'
              : 'text-red-600 dark:text-red-500',
            'ml-2 flex items-baseline text-sm font-semibold'
          )}
        >
          {stat.changeType === 'increase' ? (
            <ArrowUpIcon
              className='h-5 w-5 flex-shrink-0 self-center text-green-500 dark:text-green-400'
              aria-hidden='true'
            />
          ) : (
            <ArrowDownIcon
              className='h-5 w-5 flex-shrink-0 self-center text-red-500 dark:text-red-400'
              aria-hidden='true'
            />
          )}

          <span className='sr-only'>
            {' '}
            {stat.changeType === 'increase' ? 'Increased' : 'Decreased'} by{' '}
          </span>
          {stat.change}
        </p>
      </dd>
    </div>
  );
}
