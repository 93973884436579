import axios from 'axios';
import { useState } from 'react';
import {
  FaBolt,
  FaCog,
  FaFolderPlus,
  FaPlusSquare,
  FaSearch,
  FaSpinner,
  FaTruckPickup,
  FaUserPlus,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { IProject } from '../../api/services/project.service';
import { useAuth } from '../../hooks/useAuth';
import { Card } from '../shared/Card';
import TextEditDialog from '../shared/TextEditDialog';
import { SearchInput } from '../shared/SearchInput';
import { useSearch } from '../../hooks/useSearch';
import SubmissionCard from './SubmissionCard';
import { ActionItem, Item } from './ActionItem';

import {
  Bars4Icon,
  CalendarIcon,
  ClockIcon,
  PhotoIcon,
  TableCellsIcon,
  ViewColumnsIcon,
} from '@heroicons/react/24/outline';
import { BiBoltCircle, BiCategory } from 'react-icons/bi';

interface Props {
  // className?: string;
  // projects: IProject[] | undefined;
  // loading: boolean;
  artistUserId?: string;
}

export default function QuickActionSection({
  // className,
  // projects,
  // loading,
  artistUserId,
}: Props) {
  let navigate = useNavigate();
  const { activeTeam } = useAuth();

  const [isProjectAddDialogOpen, setIsProjectAddDialogOpen] = useState(false);
  const [isAlbumAddDialogOpen, setIsAlbumAddDialogOpen] = useState(false);
  const [isMemberAddDialogOpen, setIsMemberAddDialogOpen] = useState(false);

  const items: Item[] = [
    {
      title: 'Create a submission',
      description: 'Invite a new or existing artist to submit their work',
      icon: FaPlusSquare,
      background: 'bg-indigo-500',
      onClick: () => setIsProjectAddDialogOpen(true),
    },
    {
      title: 'Create an album',
      description: 'Bundle multiple tracks into one album and publish it',
      icon: FaFolderPlus,
      background: 'bg-sky-500',
      onClick: () => setIsAlbumAddDialogOpen(true),
    },
    {
      title: 'Find tracks',
      description: 'Search all existing tracks of the library',
      icon: FaSearch,
      background: 'bg-cyan-500',
      onClick: () => navigate('/tracks'),
    },
    {
      title: 'Invite a colleague',
      description: 'Invite a colleague to join you in managing this library',
      icon: FaUserPlus,
      background: 'bg-teal-500',
      onClick: () => setIsMemberAddDialogOpen(true),
    },
    {
      title: 'Edit library settings',
      description: 'Manage your library metadata and user permissions',
      icon: FaCog,
      background: 'bg-emerald-500',
      onClick: () => setIsMemberAddDialogOpen(true),
    },
  ];

  async function addProject({ name }: { name: string }) {
    try {
      const { data } = await api.project.addProject({
        name,
        teamId: activeTeam?.id || '',
        type: 'SUBMISSION',
      });
      if (data.result) {
        if (artistUserId) {
          await api.project.inviteProjectMember(data.result.id, {
            userId: artistUserId,
            role: 'GUEST',
            teamId: activeTeam?.id,
          });
        }
        navigate(`/submission/${data.result.id}`);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }
  async function addMember({ email }: { email: string }) {
    try {
      await api.team.inviteMember(activeTeam?.id || '', {
        targetUserEmail: email,
        targetRole: 'MEMBER',
      });
      toast.success(`Invitation sent to ${email}!`);
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function addAlbum({ name }: { name: string }) {
    if (!activeTeam) {
      toast.error('No team selected!');
      return;
    }
    try {
      const { data } = await api.album.addAlbum({
        type: 'ALBUM',
        name,
        teamId: activeTeam?.id,
      });
      if (data.result) {
        navigate(`/album/${data.result.id}`);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      <TextEditDialog
        isOpen={isProjectAddDialogOpen}
        title={'New Submission'}
        initialValue={'New Submission'}
        onCancel={() => {
          setIsProjectAddDialogOpen(false);
        }}
        onSubmit={(newValue: string) => {
          addProject({ name: newValue });
          setIsProjectAddDialogOpen(false);
        }}
      />
      <TextEditDialog
        isOpen={isAlbumAddDialogOpen}
        title={'New Album'}
        initialValue={'New Album'}
        onCancel={() => {
          setIsAlbumAddDialogOpen(false);
        }}
        onSubmit={(newValue: string) => {
          addAlbum({ name: newValue });
          setIsAlbumAddDialogOpen(false);
        }}
      />
      <TextEditDialog
        isOpen={isMemberAddDialogOpen}
        title={'Invite a colleague'}
        placeholder='Enter email address'
        onCancel={() => {
          setIsMemberAddDialogOpen(false);
        }}
        onSubmit={(newValue: string) => {
          addMember({ email: newValue });
          setIsMemberAddDialogOpen(false);
        }}
      />
      {/* <div className='flex flex-col space-y-4'>
      <div className='flex items-center space-x-2'>
        <FaLightbulb className='text-2xl text-indigo-500' />
        <div className='text-lg font-semibold'>Quick Stats</div>
      </div>

      <dl className='grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-3'>
        {stats.map((item) => (
          <StatsCard key={item.id} stat={item} />
        ))}
      </dl>
    </div> */}
      <div className='flex flex-col space-y-4 w-full h-full'>
        <div className='flex items-center space-x-2'>
          <BiCategory className='text-2xl text-indigo-500' />
          <div className='text-lg font-semibold'>Quick Actions</div>
        </div>
          <div
            className='flex lg:flex-col  justify-between w-full h-full'
          >
            {items.map((item) => (
              <ActionItem key={item.title} item={item} />
            ))}
          </div>
        </div>
    </>
  );
}
