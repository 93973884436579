import React, { FocusEvent, useEffect, useRef, useState } from 'react';

export default function InlineTextAreaEdit({
  value,
  setValue,
  isDisabled,
  className,
  rows,
  testid = '',
}: {
  value: string;
  setValue: (value: string) => void;
  isDisabled: boolean;
  className?: string;
  rows?: number;
  testid?: string;
}) {
  const [editingValue, setEditingValue] = useState(value);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setEditingValue(value);
    console.log('setting value', value);
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditingValue(event.target.value);

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.currentTarget.blur();
    }
  };

  const onBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    setValue(event.currentTarget.value);
  };
  return (
    <textarea
      data-testid={testid}
      ref={inputRef}
      className={`disabled:cursor-not-allowed ${className}`}
      // className="bg-transparent border-0 hover:bg-gray-300 hover:cursor-pointer text-lg focus:bg-gray-300 focus:ring-indigo-900 font-bold font-mono px-1"
      aria-label='Field name'
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      disabled={isDisabled}
      rows={rows}
      // style={{
      //   width: (editingValue.length < 7 ? 9 : editingValue.length + 2) + "ch",
      // }}
    />
  );
}
