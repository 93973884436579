import { InputHTMLAttributes } from 'react';
import InfoTooltip from './InfoTooltip';

interface Props {
  className?: string;
  error?: boolean;
  label?: string;
  hint?: string;
}

export default function Input({
  className = '',
  error,
  id,
  label,
  hint,
  ...rest
}: InputHTMLAttributes<HTMLInputElement> & Props) {
  return (
    <>
      {label && (
        <label
          htmlFor={id}
          className={`flex items-center text-sm font-medium dark:text-slate-200 ${
            error ? 'text-red-500' : ''
          }`}
        >
          {label}&nbsp;
          {hint && <InfoTooltip hint={hint} />}
        </label>
      )}
      <input
        id={id}
        className={`mt-1 block w-full rounded-md sm:text-sm ${className}`}
        {...rest}
      />
    </>
  );
}
