import { useState } from 'react';
import { FaCompactDisc, FaUserAstronaut } from 'react-icons/fa';
import { IAuthenticatedUser } from '../../hooks/useAuth';

export function AccountTypeStepWindow({
  user,
  nextStep,
  setAccountType,
}: {
  user: IAuthenticatedUser | null;
  nextStep: () => void;
  setAccountType: (accountType: string) => void;
}) {
  const [loading, setLoading] = useState(false);

  function setAccountTypeAndProgress(type: string) {
    setAccountType(type);
    nextStep();
  }

  return (
    <>
      {/* <span className='mt-4 text-center font-medium text-gray-700 dark:text-slate-200'>
        How are you planning to use KTR Studio?
      </span> */}
      <span className='mt-4 text-center font-medium text-gray-700 dark:text-slate-200'>
        Let's get started - It's time to set up your new account!
      </span>
      <div className='my-5 flex w-full flex-col items-center justify-around px-10 pb-2 lg:w-full lg:flex-row'>
        <div className='flex h-full basis-2/5 flex-col justify-between'>
          <div className='flex flex-col items-center space-y-2 text-center'>
            <FaUserAstronaut size={70} />
            <h2 className='text-xl font-semibold'>Artist</h2>
            <span>
              As an artist you can use KTR studio to submit all relevant files
              to your label like Demos, Master files or Stems. If you received
              an invitation from your label this is the way to go.
            </span>
          </div>
          <div>
            <div className='mt-6'>
              <button
                type='submit'
                className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={() => setAccountTypeAndProgress('ARTIST')}
              >
                Create Artist
              </button>
            </div>
          </div>
        </div>
        <div className='flex w-5/6 basis-1/5 justify-center'>
          <div className='block w-full border-b-2 border-indigo-500 lg:h-32 lg:w-0 lg:border-r-2 lg:border-b-0'>
            &nbsp;
          </div>
        </div>
        <div className='block w-full lg:hidden'>&nbsp;</div>
        <div className='flex basis-2/5 flex-col'>
          <div className='flex flex-col items-center space-y-2 text-center'>
            <FaCompactDisc size={70} />
            <h2 className='text-xl font-semibold'>Label</h2>
            <span>
              As a label you can use KTR Studio to manage your digital track
              library and automate submission processes. Working in a team?
              Don't forget to invite your colleagues
            </span>
          </div>
          <div>
            <div className='mt-6'>
              <button
                type='submit'
                className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={() => setAccountTypeAndProgress('PUBLISHER')}
              >
                Create Label
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
