import saveAs from 'file-saver';
import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import {
  FaArrowLeft,
  FaCheck,
  FaFileDownload,
  FaSpinner,
  FaTrash,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { exportMapping_Cadenzabox } from '../../api/csv/columnDefinitionsCadenzabox';
import { exportMapping_HarvestMedia } from '../../api/csv/columnDefinitionsHarverstMedia';
import { exportAsCSV } from '../../api/csv/csvExporter';
import { IFile } from '../../api/services/file.service';
import { ITeam } from '../../api/services/team.service';
import { ITrack } from '../../api/services/track.service';
import { useAuth } from '../../hooks/useAuth';
import useDialog from '../../hooks/useDialog';
import { classNames, flattenObject } from '../../utils';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import { useAlbumStudioState } from './albumState';
import ExportOptionMenu from './sections/actionBar/ExportOptionMenu';
import JSZip from 'jszip';
import { Mapping } from '../../types';
interface IFileMap {
  [key: string]: IFile[];
}

function buildFileMap(files: IFile[]) {
  const map: IFileMap = {};
  files.forEach((file) => {
    if (!map[file.parentId]) {
      map[file.parentId] = [file];
    } else {
      map[file.parentId] = [...map[file.parentId], file];
    }
  });
  return map;
}

const supportedExportFormats = {
  harvestMedia: exportMapping_HarvestMedia,
  cadenzabox: exportMapping_Cadenzabox,
};

export function AlbumActionBar() {
  const navigate = useNavigate();

  const {
    refreshData,
    activeAlbumTracks,
    activeFiles,
    isUpdating,
    activeAlbum,
    activeRole,
  } = useAlbumStudioState();

  const activeTracksMap = useMemo(() => {
    return activeAlbumTracks.reduce((acc: Mapping<ITrack>, track) => {
      acc[track.id] = track;
      return acc;
    }, {});
  }, [activeAlbumTracks]);

  const masteredFiles = useMemo(() => {
    return activeFiles.filter((f) => {
      const track = activeTracksMap[f.parentId];
      return track && track.metadata.masterStatus === 'mastered';
    });
  }, [activeFiles, activeTracksMap]);

  const unmasteredFiles = useMemo(() => {
    return activeFiles.filter((f) => {
      const track = activeTracksMap[f.parentId];
      return track && track.metadata.masterStatus !== 'mastered';
    });
  }, [activeFiles, activeTracksMap]);

  const { isDialogOpened, openDialog, closeDialog } = useDialog();
  const [isDeletingAlbum, setIsDeletingAlbum] = useState(false);
  const { activeTeam } = useAuth();

  if (!activeAlbum) return null;

  async function exportSubmission(
    exportFormat: keyof typeof supportedExportFormats
  ) {
    if (!activeAlbum) return;

    const fileMap = buildFileMap(activeFiles);

    if (activeTeam) {
      const rowObjects = await Promise.all(
        activeAlbumTracks.map((track) =>
          getTrackInfo(track, fileMap[track.id], activeTeam)
        )
      );

      const csvSeparator = exportFormat === 'cadenzabox' ? ',' : ';';
      const multipleObjectDelimiter = exportFormat === 'cadenzabox' ? ';' : ',';
      const csvString = exportAsCSV(
        rowObjects,
        supportedExportFormats[exportFormat],
        csvSeparator,
        multipleObjectDelimiter
      );
      const blob = new Blob([csvString], { type: 'text/csv' });
      saveAs(
        blob,
        `${activeAlbum.name}_${exportFormat}_${DateTime.now().toISODate()}.csv`
      );
    } else {
      toast.error('team not set');
    }
  }

  async function getTrackInfo(track: ITrack, files: IFile[], library: ITeam) {
    if (!activeAlbum) return null;

    const [trackComposerResponse, submissionRes, trackTagsRes, albumTagsRes] =
      await Promise.all([
        api.composer.getComposersByTrack(track.id),
        api.project.getProject(track.projectId),
        api.tag.getTagsByObject(track.id),
        api.tag.getTagsByObject(activeAlbum.id),
      ]);

    return flattenObject({
      library,
      track,
      submission: submissionRes.data.result,
      file: files,
      album: activeAlbum,
      composers: trackComposerResponse.data.result,
      tags: _.groupBy(trackTagsRes.data.result, 'type'),
      albumTags: _.groupBy(albumTagsRes.data.result, 'type'),
    });
  }

  const confirmDeleteAlbum = () => {
    setIsDeletingAlbum(true);
    api.album
      .deleteAlbum(activeAlbum.id)
      .then(() => {
        setIsDeletingAlbum(false);
        toast.success(
          <>
            Album <span className='font-semibold'>{activeAlbum.name}</span>{' '}
            deleted successfully
          </>
        );
        navigate('/albums');
      })
      .catch(() => {
        setIsDeletingAlbum(false);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      });
  };

  function isRoleLoaded(role: string | null) {
    return role !== null && role !== '' && role !== undefined;
  }

  function isPublisher(role: string | null) {
    return role === 'OWNER' || 'MEMBER';
  }

  async function exportFilesAsZip(mastered: boolean) {
    if (!activeAlbum) return;

    try {
      const filesToExport = mastered ? masteredFiles : unmasteredFiles;

      if (!filesToExport.length) {
        return toast.warning(
          `There are no ${mastered ? 'mastered' : 'unmastered'} files to export`
        );
      }

      const zip = new JSZip();

      await Promise.all(
        filesToExport.map(async (file) => {
          const track = activeTracksMap[file.parentId];

          const urlResponse = await api.file.getFilePresignedDownloadUrl(
            file.id
          );

          const response = await fetch(urlResponse.data.result.presignedUrl);

          const fileBlob = await response.blob();

          zip.file(
            `${activeAlbum?.metadata.albumCode}-${track.metadata.trackNumber}_${
              track.isrc ? `${track.isrc}_` : ''
            }${track.name}_${activeTeam?.name}${
              track.metadata.masterStatus === 'mastered'
                ? ''
                : `_${track.metadata.masterStatus || 'draft'}`
            }.wav`,
            fileBlob
          );
        })
      );

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      saveAs(zipBlob, `${activeAlbum.name}_${DateTime.now().toISODate()}.zip`);
    } catch (error) {
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  return (
    <div className='flex flex-row justify-between'>
      <button
        className='flex items-center space-x-2 rounded bg-indigo-600 px-4 font-semibold text-white hover:bg-indigo-800'
        onClick={() => navigate('/albums')}
      >
        <FaArrowLeft />
        <div>Back</div>
      </button>

      <div className='flex items-center space-x-4'>
        {isRoleLoaded(activeRole) &&
          isPublisher(activeRole) &&
          activeAlbum.status === 'DONE' && (
            <ExportOptionMenu
              btnLabel={
                <>
                  <div>Download</div>
                  <FaFileDownload />
                </>
              }
              items={[
                {
                  label: 'Download unmastered files',
                  onClick: () => exportFilesAsZip(false),
                  icon: <FaFileDownload className='mr-2 h-5 w-5' />,
                  disabled: !unmasteredFiles.length,
                  disabledTooltipMessage: `There are no files to export`,
                },
                {
                  label: 'Dowload mastered files',
                  onClick: () => exportFilesAsZip(true),
                  icon: <FaFileDownload className='mr-2 h-5 w-5' />,
                  disabled: !masteredFiles.length,
                  disabledTooltipMessage: `There are no files to export`,
                },
              ]}
            />
          )}
        {activeAlbum.status === 'DRAFT' && (
          <button
            className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
            onClick={() => {
              api.album
                .updateAlbumPartially(activeAlbum.id, {
                  status: 'DONE',
                })
                .then(() => {
                  refreshData('activeProject');
                })
                .catch((e) => {
                  const result = e.response.data.result || [];

                  if (
                    result.every(
                      (r: { fieldName?: string }) => r.fieldName === 'track'
                    )
                  ) {
                    toast.error(
                      <>
                        <div>
                          {result[0].message}
                          <div>Tracks:</div>
                          {result
                            .map((msj: { objectId?: string }) => msj.objectId)
                            .join(', ')}
                        </div>
                      </>
                    );
                  } else {
                    toast.error(result[0].message || 'Something went wrong');
                  }
                });
            }}
          >
            <div>Publish</div>
          </button>
        )}
        {/* {activeAlbum.status === 'DONE' && (
          <button className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'>
            <div>Publish changes</div>
          </button>
        )} */}
        <ExportOptionMenu
          items={[
            {
              label: 'Export Cadenzabox',
              onClick: () => activeTeam && exportSubmission('cadenzabox'),
            },
            {
              label: 'Export Harvest Media',
              onClick: () => activeTeam && exportSubmission('harvestMedia'),
            },
          ]}
        />
        <button
          className='group flex w-full items-center rounded-md text-sm'
          onClick={openDialog}
        >
          <FaTrash
            className='mr-2 h-5 w-5 hover:scale-110'
            aria-hidden='true'
          />
        </button>
        <div className='flex justify-end space-x-4'>
          <button
            disabled={!isUpdating}
            className={classNames(
              'flex  items-center space-x-2 rounded bg-emerald-600 px-4 py-2 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700',
              isUpdating ? 'bg-sky-600' : ''
            )}
          >
            {isUpdating ? <FaSpinner className='animate-spin' /> : <FaCheck />}
          </button>
        </div>
      </div>
      <DeleteConfirmationDialog
        isLoading={isDeletingAlbum}
        isOpen={isDialogOpened}
        title={`Delete album `}
        targetName={activeAlbum.name}
        close={closeDialog}
        onSubmit={confirmDeleteAlbum}
      />
    </div>
  );
}
