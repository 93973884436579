import { DateTime } from 'luxon';
import { IComposer } from '../services/composer.service';
import { IProject } from '../services/project.service';
import { ITag } from '../services/tag.service';
import { ITeam } from '../services/team.service';
import { ITrack } from '../services/track.service';

export function buildListInfo(
  team: ITeam,
  project: IProject,
  track: ITrack,
  composers: IComposer[],
  tags: ITag[]
) {
  const description = `${team.website}, ${team.email} | ${tags
    .filter((x) => x.type === 'MOOD')
    .map((tag) => tag.name)
    .join(', ')} | ${tags
    .filter((x) => x.type === 'INSTRUMENT')
    .map((tag) => tag.name)
    .join(', ')}`;
  const trackAlbumCode = `${project.metadata.albumCode}_${track.metadata.trackNumber}`;
  const copyright = `${DateTime.now().toFormat('yyyy')} (${
    team.name
  } ${trackAlbumCode}) ${team.website}`;
  const embedder = 'KTR Studio';
  return {
    // Archival Location
    IARL: team.name, // Trailer Rebel
    // Artist
    IART: team.name, // Trailer Rebel
    // Comments
    // www.trailerrebel.com, Info@trailerrebel.com | Mystical, Dramatic, Positive, Hopeful, Optimistic, Inspiring, Glorious, Majestic, Uplifting, Triumphant, Magical, Powerful, Calm | Booms, Brass, Braam, Drums, Percussion, Hits, Pads, Textures, Strings, Signatures, Synths, Risers
    ICMT: description,

    // Copyright
    ICOP: copyright, // 2023 (Trailer Rebel TR076_01) https://www.trailerrebel.com/
    // Creation Date
    ICRD: DateTime.now().toFormat('yyyy-MM-dd'), // 2023-01-01
    // Genre
    IGNR: track.metadata.catalogue, // film, cinematic, trailer

    // Name (Title)
    INAM: track.name, // Alive
    // Product (Album)
    IPRD: project.name, // TBD -> Spark - (Uplifting Epic Postive)
    // Software
    ISFT: embedder, // soundminer

    // not used here
    // 'ISRC': track.isrc
  };
}
