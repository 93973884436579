import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Banner } from './components/global/Banner';
import Navbar from './components/global/Navbar';
import { globalThemeStyles } from './config/globalStyles';
import { useAuth } from './hooks/useAuth';
import { useGlobalState } from './state';
import { classNames } from './utils';
import { PlayerProvider } from './hooks/usePlayer';

export default function StudioLayout() {
  const { isAuthenticated } = useAuth();
  const { theme } = useGlobalState();

  useEffect(() => {
    if (theme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme]);

  return (
    <PlayerProvider>
      <div
        className={
          'flex h-screen w-full flex-col bg-slate-200 dark:bg-slate-700'
        }
      >
        {/* Navbar */}
        {isAuthenticated && <Navbar />}
        {/* Banner */}
        {isAuthenticated && <Banner />}
        {/* Content */}
        <div
          className={classNames(
            'mx-auto flex w-full max-w-1920 flex-1 px-2 pb-8 pt-4 sm:px-4 lg:px-8',
            globalThemeStyles.container
          )}
        >
          <Outlet />
        </div>
        {/* </div> */}
        {/* {!isAuthenticated && (
        <div className="text-slate-400 bg-slate-200 flex justify-center p-8 space-x-1">
          <div>Powered by</div>
          <a
            href="https://ktr.studio"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            KTR Studio
          </a>
        </div>
      )} */}
      </div>
    </PlayerProvider>
  );
}
