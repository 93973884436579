import React, { FocusEvent, useEffect, useRef, useState } from 'react';

export default function InlineEdit({
  value,
  setValue,
  isDisabled,
  className,
  pattern,
  type,
  min,
  max,
  id,
}: {
  value: string;
  setValue: (value: string) => void;
  isDisabled: boolean;
  className?: string;
  pattern?: string;
  type?: string;
  min?: number;
  max?: number;
  id?: string;
}) {
  const [editingValue, setEditingValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = event.target.value;
    setEditingValue(max && type === 'number' && +v > max ? String(max) : v);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.currentTarget.blur();
    }
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    setValue(event.currentTarget.value);
  };

  return (
    <input
      id={id}
      ref={inputRef}
      className={`disabled:cursor-not-allowed ${className}`}
      type={type ? type : 'text'}
      aria-label='Field name'
      value={editingValue}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      disabled={isDisabled}
      pattern={pattern}
      min={min}
      max={max}
      // style={{
      //   width: (editingValue.length < 7 ? 9 : editingValue.length + 2) + "ch",
      // }}
    />
  );
}
