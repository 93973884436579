import { useCallback, useEffect, useState } from 'react';
import {
  FaFileImport,
  FaSpinner,
  FaUserAstronaut,
  FaUsers,
} from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../api';
import { IProject } from '../api/services/project.service';
import Loader from '../components/shared/Loader';
import { Sidebar } from '../components/shared/Sidebar';
import { Person } from '../components/studio/Person';
import { IComposer } from '../api/services/composer.service';
import { useAuth } from '../hooks/useAuth';
import { ITeamMembership } from '../api/services/team.service';
import { Cell } from '../components/shared/Cell';
import { ContractStatusBadge } from '../components/shared/ContractStatusBadge';
import { Contract } from '../api/services/contract.service';
import { Tooltip } from 'flowbite-react';
import { classNames } from '../utils';
import { globalThemeStyles } from '../config/globalStyles';
import { SearchInput } from '../components/shared/SearchInput';
import { useSearch } from '../hooks/useSearch';
import { useGlobalState } from '../state';
import { getNavbarItemClasses } from '../components/global/Navbar';
import { ITrack, TrackWithFiles } from '../api/services/track.service';
import SubmissionCard from '../components/dashboard/SubmissionCard';
import TrackListRow from '../components/studio/sections/trackList/TrackListRow';
import { downloadFile } from '../utils/fileHelper';
import { IFile } from '../api/services/file.service';
import axios from 'axios';
import DeleteConfirmationDialog from '../components/shared/DeleteConfirmationDialog';
import TextEditDialog from '../components/shared/TextEditDialog';
import { capitalize } from 'lodash';

export const Composers = () => {
  const { theme } = useGlobalState();
  const { activeTeam } = useAuth();
  const [composers, setComposers] = useState<IComposer[]>([]);
  const [artistsMap, setArtistsMap] = useState<Map<string, ITeamMembership>>();

  const [projects, setProjects] = useState<IProject[]>();
  const [tracks, setTracks] = useState<TrackWithFiles[]>([]);

  const [loadingComposers, setLoadingComposers] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [activeComposer, setActiveComposer] = useState<IComposer>();
  const [activeComposerContracts, setActiveComposerContracts] =
    useState<Contract[]>();

  const [activeTab, setActiveTab] = useState('tracks');

  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [objectToBeRenamed, setObjectToBeRenamed] = useState<
    ((IFile | ITrack) & { type: string }) | null
  >(null);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [objectToBeDeleted, setObjectToBeDeleted] = useState<
    ((IFile | ITrack) & { type: string }) | null
  >(null);

  const [isDeleting, setIsDeleting] = useState(false);

  const showError = () => {
    toast.error(
      'Something really went wrong, you might want to contact support!'
    );
  };

  const {
    onSearchChange,
    found: foundProjects,
    searchString,
    setSearchString,
  } = useSearch<IProject>({
    data: projects || [],
    filterFn: (v: string) => (p) => p.name.toLowerCase().includes(v),
  });

  const onClickComposer = (c: IComposer) => {
    setActiveComposer(c);
    setIsLoadingContent(true);
    setActiveTab('tracks');
    setSearchString('');
    Promise.all([
      api.project.searchProject({
        condition: {
          type: 'eq',
          key: 'composer.id',
          value: c.id,
        },
        size: 100,
      }),
      api.track.searchTrack({
        condition: {
          type: 'and',
          conditions: [
            {
              key: 'composer.id',
              type: 'eq',
              value: c.id,
            },
          ],
        },
        size: 10000,
      }),
      api.contract.getComposerContracts(c.id),
    ])
      .then(([projectsRes, tracksRes, contractsRes]) => {
        const submissions = (projectsRes.data.result?.content || []).filter(
          (p) => p.type === 'SUBMISSION'
        );
        setProjects(submissions);
        setTracks(tracksRes.data.result?.content || []);
        setActiveComposerContracts(contractsRes.data.result);
      })
      .catch(showError)
      .finally(() => {
        setIsLoadingContent(false);
      });
  };

  useEffect(() => {
    if (activeTeam) {
      setLoadingComposers(true);
      Promise.all([
        api.composer.getTenantComposers(activeTeam.id),
        api.team.getTeamMembers(activeTeam.id),
      ])
        .then(([composersRes, artitstRes]) => {
          setComposers(composersRes.data.result);
          setArtistsMap(
            new Map(
              artitstRes.data.result.map((artist) => [artist.userId, artist])
            )
          );
        })
        .catch(showError)
        .finally(() => {
          setLoadingComposers(false);
        });
    }
  }, [activeTeam]);

  const renderComposers = () => {
    if (!composers) {
      return (
        <div className='pt-5 text-center font-semibold'>No Composers yet</div>
      );
    }

    return composers.map((c) => (
      <Person
        firstname={c.firstName}
        lastname={c.lastName}
        key={c.id}
        onClick={() => {
          onClickComposer(c);
        }}
        active={activeComposer?.id === c.id}
      />
    ));
  };

  const activeComposerCreatedBy =
    activeComposer?.createdBy && artistsMap?.get(activeComposer.createdBy);

  const fetchTracks = async () => {
    if (!activeComposer) return;
    const res = await api.track.searchTrack({
      condition: {
        type: 'and',
        conditions: [
          {
            key: 'composer.id',
            type: 'eq',
            value: activeComposer.id,
          },
        ],
      },
      size: 10000,
    });
    setTracks(res.data.result?.content || []);
  };

  async function renameItem(id: string, type: any, props: { name: string }) {
    try {
      if (type === 'track') {
        const response = await api.track.updateTrackPartially(id, props);
        if (response.status === 200) {
          await fetchTracks();
          toast.success('Track renamed successfully');
        }
      } else {
        toast.warn("You can't rename that");
      }
    } catch (e) {
      if (
        type === 'track' &&
        axios.isAxiosError(e) &&
        e.response?.status === 409
      ) {
        return toast.error('Track with the same name already exists');
      }

      console.log(e);
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  const onRenameCancel = useCallback(() => {
    setIsRenameDialogOpen(false);
    setObjectToBeRenamed(null);
  }, []);

  const onDeleteCancel = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setObjectToBeDeleted(null);
  }, []);

  async function deleteItem(id: string, type: any) {
    try {
      if (type === 'track') {
        setIsDeleting(true);
        const response = await api.track.deleteTrack(id);
        if (response.status === 204) {
          await fetchTracks();
          toast.success('Track deleted successfully');
        }
        setIsDeleting(false);
      } else {
        toast.warn("You can't delete that");
      }
    } catch (e) {
      toast.error(
        'Something really went wrong, you might want to contact support!'
      );
    }
  }

  const onDeleteSubmit = useCallback(() => {
    setIsDeleteDialogOpen(false);
    if (objectToBeDeleted) {
      deleteItem(objectToBeDeleted?.id, objectToBeDeleted?.type);
      setObjectToBeDeleted(null);
      // TODO: also delete file??
    }
    // eslint-disable-next-line
  }, [objectToBeDeleted]);

  const onRenameSubmit = useCallback(
    (newValue: string) => {
      if (objectToBeRenamed) {
        renameItem(objectToBeRenamed.id, objectToBeRenamed.type, {
          name: newValue,
        });
      }
      setIsRenameDialogOpen(false);
      setObjectToBeRenamed(null);
    },
    // eslint-disable-next-line
    [objectToBeRenamed]
  );

  const renderContent = () => {
    if (isLoadingContent)
      return (
        <div className='flex items-center justify-center p-20'>
          <FaSpinner size={30} className='animate-spin' />
        </div>
      );

    if (activeTab === 'submissions') {
      return (
        <div className='MiniScrollbar grid grid-cols-1 gap-8 overflow-auto p-4 lg:grid-cols-2 xl:grid-cols-3'>
          {foundProjects.map((project) => (
            <SubmissionCard key={project.id} project={project} />
          ))}
        </div>
      );
    }
    if (activeTab === 'tracks') {
      return (
        <div className='MiniScrollbar grid grid-cols-1 gap-8 overflow-auto p-4 lg:grid-cols-2 xl:grid-cols-3'>
          {(tracks || []).map((track) => {
            const curProject = (projects || []).find(
              (p) => p.id === track.projectId
            );

            return (
              <TrackListRow
                // onClickSetMaster={(track: ITrack) => () => {}}
                onClickSetMaster={(track: ITrack) => () => {
                  api.track.updateTrackPartially(track.id, {
                    metadata: {
                      ...track.metadata,
                      masterStatus: 'mastered',
                    },
                  });
                  // TODO: refreshData
                  // refreshData('activeProjectTracks');
                }}
                track={track}
                isActive={false}
                file={track.files?.[0]}
                key={track.id}
                showDelete
                downloadFile={(file) =>
                  downloadFile(
                    file,
                    track,
                    curProject ? curProject : null,
                    activeTeam
                  )
                }
                onRename={() => {
                  setObjectToBeRenamed({ ...track, type: 'track' });
                  setIsRenameDialogOpen(true);
                }}
                onDelete={() => {
                  setObjectToBeDeleted({ ...track, type: 'track' });
                  setIsDeleteDialogOpen(true);
                }}
              />
            );
          })}
        </div>
      );
    }

    return null;
  };

  if (loadingComposers) return <Loader />;

  const frameworkAgreement = activeComposerContracts?.find(
    (c) => c.contract?.type === 'FA'
  );

  return (
    <div className='flex flex-grow gap-x-5'>
      <div className='flex w-1/3 flex-col'>
        <Sidebar
          title='Composers'
          icon={<FaUsers size={16} className='text-indigo-700' />}
        >
          <div className='grow'>
            {loadingComposers ? (
              <div className='flex items-center justify-center p-20'>
                <FaSpinner size={30} className='animate-spin' />
              </div>
            ) : (
              renderComposers()
            )}
          </div>
        </Sidebar>
      </div>
      <div className='flex w-2/3 flex-col gap-y-5'>
        {activeComposer && (
          <div className='flex flex-col overflow-hidden rounded-2xl bg-gray-50 shadow-xl dark:bg-gray-800'>
            <div className='flex min-h-[40px] items-center justify-between border-b py-2 px-4 dark:border-gray-600'>
              <div className='mr-10 flex items-center bg-gray-50 dark:bg-gray-800'>
                <div className='flex'>
                  <FaUserAstronaut size={16} className='text-indigo-700' />
                </div>
                <div className='ml-2 flex text-lg font-medium leading-6 text-slate-700 dark:text-slate-200'>
                  Composer information
                </div>
              </div>
            </div>
            <div className='w-full bg-white p-2 px-4 pb-6 dark:bg-gray-900'>
              <div className='space-y-3 divide-y'>
                <div className='grid grid-cols-3 gap-x-4 pt-2'>
                  <Cell label='First Name'>{activeComposer.firstName}</Cell>
                  <Cell label='Middle Name'>{activeComposer.middleName}</Cell>
                  <Cell label='Last Name'>{activeComposer.lastName}</Cell>
                </div>
                <div className='grid grid-cols-2 gap-x-4 pt-2'>
                  <Cell label='Email address' className='break-all'>
                    {activeComposer.email}
                  </Cell>
                  <Cell label='Country'>{activeComposer.country}</Cell>
                </div>
                <div className='pt-2'>
                  <Cell label='Street address'>{activeComposer.street}</Cell>
                </div>
                <div className='grid grid-cols-3 gap-x-4 pt-2'>
                  <Cell label='City'>{activeComposer.city}</Cell>
                  <Cell label='ZIP / Postal code'>{activeComposer.zip}</Cell>
                  <Cell label='State / Provinces'>{activeComposer.state}</Cell>
                </div>
                <div className='grid grid-cols-2 gap-x-4 pt-2'>
                  <Cell label='Performing Rights Organisation (PRO) *'>
                    {activeComposer.pro}
                  </Cell>
                  <Cell label='Interested Party Information Number (IPI) *'>
                    {activeComposer.ipi}
                  </Cell>
                </div>
                <div className='grid grid-cols-2 gap-x-4 pt-2'>
                  <Cell label='Created by'>
                    {activeComposerCreatedBy && (
                      <>
                        {activeComposerCreatedBy.user.firstname}{' '}
                        {activeComposerCreatedBy.user.lastname}
                      </>
                    )}
                  </Cell>
                </div>
                <div className='grid grid-cols-2 gap-x-4 pt-2'>
                  <Cell label='Framework Agreement status'>
                    {frameworkAgreement ? (
                      <Tooltip
                        content={`${frameworkAgreement.contract.name} - Version ${frameworkAgreement.contract.version}`}
                      >
                        <ContractStatusBadge
                          status={frameworkAgreement?.status || 'DRAFT'}
                          className='inline-flex'
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip content='No contract signature requested yet'>
                        No contract
                      </Tooltip>
                    )}
                  </Cell>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={classNames(
            `flex w-full grow flex-col overflow-hidden rounded-2xl shadow-xl`,
            globalThemeStyles.box
          )}
        >
          <div className='flex w-full items-center justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-800'>
            <div className='flex items-center'>
              <div className='mr-10 flex items-center rounded-lg'>
                <div className=''>
                  <FaFileImport size={20} className='text-indigo-700' />
                </div>
                <span className='ml-4 flex gap-x-4 text-lg font-medium leading-6'>
                  {[
                    {
                      label: 'Tracks',
                      id: 'tracks',
                    },
                    {
                      label: 'Submissions',
                      id: 'submissions',
                    },
                  ].map((v) => (
                    <button
                      className={classNames(
                        `inline-flex items-center border-b-4 px-1 pt-1 font-medium ${getNavbarItemClasses(
                          activeTab === v.id,
                          theme
                        )}`
                      )}
                      onClick={() => setActiveTab(v.id)}
                    >
                      {v.label}
                    </button>
                  ))}
                </span>
              </div>
            </div>
            {activeTab === 'submissions' && (
              <div className='flex items-center gap-x-8'>
                <SearchInput onChange={onSearchChange} value={searchString} />
              </div>
            )}
          </div>
          {renderContent()}
        </div>
      </div>
      <DeleteConfirmationDialog
        isLoading={isDeleting}
        isOpen={isDeleteDialogOpen}
        title={`Delete ${
          objectToBeDeleted && capitalize(objectToBeDeleted?.type)
        }`}
        targetName={`${objectToBeDeleted && objectToBeDeleted.name}`}
        close={onDeleteCancel}
        onSubmit={onDeleteSubmit}
      />
      <TextEditDialog
        isOpen={isRenameDialogOpen}
        title={'Rename'}
        initialValue={`${objectToBeRenamed ? objectToBeRenamed?.name : ''}`}
        onCancel={onRenameCancel}
        onSubmit={onRenameSubmit}
      />
    </div>
  );
};
