import axios from 'axios';
import { capitalize } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaArchive, FaSpinner } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../api';
import { IFile } from '../../../api/services/file.service';
import { ITrack } from '../../../api/services/track.service';
import { useAuth } from '../../../hooks/useAuth';
import { downloadFile } from '../../../utils/fileHelper';
import DeleteConfirmationDialog from '../../shared/DeleteConfirmationDialog';
import { Sidebar } from '../../shared/Sidebar';
import TextEditDialog from '../../shared/TextEditDialog';
import { useDropzoneState } from '../StudioDropzoneWrapper';
import { useStudioState } from '../studioState';
import { DraggableItem } from './DraggableItem';
import { FileUploadRow } from './trackList/FileUploadRow';
import TrackListRow from './trackList/TrackListRow';
import { classNames } from '../../../utils';

export const DragItemTypes = {
  FILE: 'file',
  TRACK: 'track',
};

function StudioFileListInternal() {
  const {
    // activeProjectRole,
    activeTrack,
    setActiveTrack,
    activeFiles,
    activeFilesLoading,
    activeProjectTracks,
    activeProjectTracksLoading,
    activeProject,
    refreshData,
    canBeModified,
    setActiveProjectTracks,
  } = useStudioState();

  const { isDragActive } = useDropzoneState();

  const {
    openFileDialog,
    // isDragActive,
    acceptedFiles,
    setUploadCompleted,
    activeFileRole,
  } = useDropzoneState();

  const { activeTeam, user } = useAuth();

  // Delete
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [objectToBeDeleted, setObjectToBeDeleted] = useState<
    ((IFile | ITrack) & { type: string }) | null
  >(null);

  // Rename
  const [isRenameDialogOpen, setIsRenameDialogOpen] = useState(false);
  const [objectToBeRenamed, setObjectToBeRenamed] = useState<
    ((IFile | ITrack) & { type: string }) | null
  >(null);

  // const [tracksInUpload, setTracksInUpload] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (activeProjectTracks.length > 0) {
      if (activeTrack === null) {
        setActiveTrack(activeProjectTracks[0]);
      } else {
        const newTrackData = activeProjectTracks.find(
          (track) => track.id === activeTrack.id
        );
        if (newTrackData) {
          setActiveTrack(newTrackData);
        }
      }
    }
    // eslint-disable-next-line
  }, [activeProjectTracks, activeTrack]);

  async function renameItem(id: string, type: any, props: { name: string }) {
    try {
      if (type === 'track') {
        const response = await api.track.updateTrackPartially(id, props);
        if (response.status === 200) {
          // fetchAll(project, false);
          refreshData('activeProjectTracks');
          toast.success('Track renamed successfully');
        }
      } else {
        toast.warn("You can't rename that");
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (type === 'track' && e.response?.status === 409) {
          return toast.error('Track with the same name already exists');
        }
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function deleteItem(id: string, type: any) {
    try {
      if (type === 'track') {
        // TODO: handle file deletion?
        setIsDeleting(true);
        const response = await api.track.deleteTrack(id);
        setIsDeleting(false);
        if (response.status === 204) {
          if (activeTrack && activeTrack.id === id) {
            setActiveTrack(null);
          }
          // fetchAll(project, false);
          refreshData('activeProjectTracks');
          toast.success('Track deleted successfully');
        }
      } else {
        toast.warn("You can't delete that");
      }
    } catch (e) {
      setIsDeleting(false);
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  const onDeleteCancel = useCallback(() => {
    setIsDeleteDialogOpen(false);
    setObjectToBeDeleted(null);
  }, []);

  const onDeleteSubmit = useCallback(() => {
    setIsDeleteDialogOpen(false);
    if (objectToBeDeleted) {
      deleteItem(objectToBeDeleted?.id, objectToBeDeleted?.type);
      setObjectToBeDeleted(null);
      // TODO: also delete file??
    }
    // eslint-disable-next-line
  }, [objectToBeDeleted]);

  const onRenameCancel = useCallback(() => {
    setIsRenameDialogOpen(false);
    setObjectToBeRenamed(null);
  }, []);

  const onRenameSubmit = useCallback(
    (newValue: string) => {
      if (objectToBeRenamed) {
        renameItem(objectToBeRenamed.id, objectToBeRenamed.type, {
          name: newValue,
        });
      }
      setIsRenameDialogOpen(false);
      setObjectToBeRenamed(null);
    },
    // eslint-disable-next-line
    [objectToBeRenamed]
  );

  // TODO: unsure if needed
  const onUploadStarted = (file: IFile, track: ITrack) => {
    // window.onbeforeunload = () => true;
    // setTracksInUpload([...tracksInUpload, track.id]);
  };

  const onUploadCompleted = (file: IFile, track: ITrack) => {
    refreshData('activeProjectTracks');
    refreshData('activeFiles');
    setUploadCompleted(`${file.name}+${file.size}`);
  };

  const moveTrack =
    (track: ITrack) =>
    async (dragIndex: number, hoverIndex: number, dragItem: ITrack) => {
      const newTrackObject = (activeProjectTracks || []).map((pt) => {
        if (pt.id === track.id) {
          return {
            ...pt,
            metadata: {
              ...pt.metadata,
              trackNumber: String(dragIndex),
            },
          };
        }
        if (pt.id === dragItem.id) {
          return {
            ...pt,
            metadata: {
              ...pt.metadata,
              trackNumber: String(hoverIndex),
            },
          };
        }
        return pt;
      });

      setActiveProjectTracks(newTrackObject);

      Promise.all([
        api.track.updateTrackPartially(dragItem.id, {
          metadata: {
            ...dragItem.metadata,
            trackNumber: String(hoverIndex),
          },
        }),
        api.track.updateTrackPartially(track.id, {
          metadata: {
            ...track.metadata,
            trackNumber: String(dragIndex),
          },
        }),
      ]).catch(console.error);
    };

  return (
    <Sidebar
      title='Tracks'
      onClickPlus={openFileDialog}
      icon={<FaArchive size={16} className='text-indigo-700' />}
      className={classNames(isDragActive ? 'bg-blue-300' : '')}
    >
      <div>
        {activeProject &&
          activeTeam &&
          activeFileRole === 'MAIN' &&
          acceptedFiles
            .filter(
              (file) =>
                (file.type === 'audio/wav' || file.type === 'audio/x-wav') &&
                file.name
            )
            .map((file, index) => {
              const futureTrackNumber = activeProjectTracks.length + index + 1;
              return (
                <FileUploadRow
                  key={file.name}
                  file={file}
                  activeProject={activeProject}
                  user={user}
                  team={activeTeam}
                  futureTrackNumber={futureTrackNumber}
                  onUploadStarted={(file: IFile, track: ITrack) =>
                    onUploadStarted(file, track)
                  }
                  onUploadCompleted={(file: IFile, track: ITrack) =>
                    onUploadCompleted(file, track)
                  }
                />
              );
            })}
        <DndProvider backend={HTML5Backend}>
          {!activeFilesLoading &&
            activeProjectTracks
              // filter tracks where upload is not ready yet
              // .filter((track) => {
              //   const file = activeFiles.find((f) => f.parentId === track.id);
              //   return file ? file.status === 'READY' : false;
              // })
              .sort((a, b) => +a.metadata.trackNumber - +b.metadata.trackNumber)
              .map((track) => {
                let fileToPlay: IFile | null = null;
                const masterFile = activeFiles.find(
                  (file) => file.parentId === track.id && file.role === 'MASTER'
                );

                if (masterFile) {
                  fileToPlay = masterFile;
                } else {
                  fileToPlay =
                    activeFiles.find(
                      (file) =>
                        file.parentId === track.id && file.role === 'MAIN'
                    ) || null;
                }

                return (
                  <DraggableItem<ITrack>
                    key={track.id}
                    index={+track.metadata.trackNumber}
                    move={moveTrack(track)}
                    item={track}
                  >
                    <TrackListRow
                      onClickSetMaster={(track: ITrack) => () => {
                        api.track
                          .updateTrackPartially(track.id, {
                            metadata: {
                              ...track.metadata,
                              masterStatus:
                                track.metadata.masterStatus === 'mastered'
                                  ? 'draft'
                                  : 'mastered',
                            },
                          })
                          .then(() => {
                            refreshData('activeProjectTracks');
                            refreshData('activeProject');
                          });
                      }}
                      downloadFile={(file) =>
                        downloadFile(
                          file,
                          track,
                          activeProject,
                          activeTeam,
                          `${track.isrc ? `${track.isrc}_` : ''}${track.name}_${
                            activeTeam?.name
                          }${
                            track.metadata.masterStatus === 'mastered'
                              ? ''
                              : `_${track.metadata.masterStatus || 'draft'}`
                          }.wav`
                        )
                      }
                      onRename={() => {
                        setObjectToBeRenamed({ ...track, type: 'track' });
                        setIsRenameDialogOpen(true);
                      }}
                      onDelete={() => {
                        setObjectToBeDeleted({ ...track, type: 'track' });
                        setIsDeleteDialogOpen(true);
                      }}
                      track={track}
                      isActive={track.id === activeTrack?.id}
                      file={fileToPlay}
                      showDelete={canBeModified}
                      onSelected={() => {
                        setActiveTrack(track);
                      }}
                    />
                  </DraggableItem>
                );
              })}
        </DndProvider>
        {activeProjectTracksLoading && (
          <div className='flex items-center justify-center p-20 text-slate-500'>
            <FaSpinner size={30} className='animate-spin' />
          </div>
        )}
        {!activeProjectTracksLoading &&
          activeProjectTracks.length === 0 &&
          acceptedFiles.length === 0 && (
            <div className='flex flex-col items-center justify-center p-8 text-slate-500'>
              <div className='mb-2 font-semibold text-slate-500'>
                No tracks yet
              </div>
              <div className='flex flex-col items-center justify-center space-y-2'>
                <p className='hidden text-center md:flex'>
                  Drag and drop or click below to select files
                </p>
              </div>
            </div>
          )}
        <DeleteConfirmationDialog
          isLoading={isDeleting}
          isOpen={isDeleteDialogOpen}
          title={`Delete ${
            objectToBeDeleted && capitalize(objectToBeDeleted?.type)
          }`}
          targetName={`${objectToBeDeleted && objectToBeDeleted.name}`}
          close={onDeleteCancel}
          onSubmit={onDeleteSubmit}
        />
        <TextEditDialog
          isOpen={isRenameDialogOpen}
          title={'Rename'}
          initialValue={`${objectToBeRenamed ? objectToBeRenamed?.name : ''}`}
          onCancel={onRenameCancel}
          onSubmit={onRenameSubmit}
        />
      </div>
    </Sidebar>
  );
}
const StudioFileList = memo(StudioFileListInternal);
export default StudioFileList;
