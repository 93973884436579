import axios from 'axios';
import copy from 'copy-to-clipboard';
import { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../api';
import { useAuth } from '../hooks/useAuth';

export default function Dashboard() {
  const { activeTeam } = useAuth();

  const [targetEmail, setTargetEmail] = useState('');
  const [createdInviteToken, setCreatedInviteToken] = useState('');

  async function inviteMember() {
    if (!activeTeam) {
      return;
    }
    try {
      let { status, data } = await api.team.inviteMember(activeTeam.id, {
        targetUserEmail: targetEmail,
        targetRole: 'MEMBER',
      });
      if (status === 200 && data.result) {
        const { token } = data.result;
        setCreatedInviteToken(token);
      } else {
        toast.error('Error on invite');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error('Something really went wrong!');
      }
    }
  }

  const uri = `${window.location.protocol}//${window.location.hostname}/signup?inviteId=${createdInviteToken}`;

  return (
    <div className='flex h-full items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8'>
      <div className='w-full max-w-md space-y-8'>
        <div className='text-slate-700'>
          <div>Your Team Name: {activeTeam?.name}</div>
          <div>
            <label
              htmlFor='email-address'
              className='block text-sm font-medium text-gray-700'
            >
              Email address
            </label>
            <div className='mt-1'>
              <input
                id='email-address'
                name='email'
                type='email'
                autoComplete='email'
                required
                className='w-full rounded-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 sm:text-sm'
                placeholder=''
                onChange={(targetEmail) =>
                  setTargetEmail(targetEmail.target.value)
                }
                defaultValue={targetEmail}
              />
            </div>
          </div>
          <button
            type='submit'
            className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            onClick={() => inviteMember()}
          >
            Send Team Invite
          </button>
          <div className='mt-10'>
            {createdInviteToken && (
              <>
                <div className='flex flex-col items-center'>
                  <h2 className='font-semibold'>Invite Token</h2>
                  <span
                    className='cursor-pointer font-mono'
                    onClick={() =>
                      copy(createdInviteToken) && toast.info('Copied!')
                    }
                  >
                    {createdInviteToken}
                  </span>
                </div>
                <div className='mt-5 flex flex-col items-center'>
                  <h2 className='font-semibold'>Link</h2>
                  <span
                    className='cursor-pointer font-mono'
                    onClick={() => copy(uri) && toast.info('Copied!')}
                  >
                    {uri}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
