import { useEffect } from 'react';
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Breadcrumbs, init, reactRouterV6Instrumentation } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Navigate,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
  useSearchParams,
} from 'react-router-dom';
import StudioLayout from './StudioLayout';
import { RELEASE_VERSION, SENTRY_DSN } from './config/config';
import { AuthProvider, useAuth } from './hooks/useAuth';
import AlbumDashboard from './routes/AlbumDashboard';
import AlbumStudioView from './routes/AlbumStudioView';
import Dashboard from './routes/Dashboard';
import Onboarding from './routes/Onboarding';
import ProjectStudioView from './routes/ProjectStudioView';
import TagsRoute from './routes/Tags';
import Team from './routes/Team';
import CheckoutCallback from './routes/generic/CheckoutCallback';
import Login from './routes/generic/Login';
import MajorError from './routes/generic/MajorError';
import NotFound from './routes/generic/NotFound';
import OAuthVerification from './routes/generic/OAuthVerification';
import PasswordReset from './routes/generic/PasswordReset';
import PasswordResetExecute from './routes/generic/PasswordResetExecute';
import SignUp from './routes/generic/SignUp';
import { GlobalStateProvider } from './state';
import { ArtistsDashboard } from './routes/ArtistsDashboard';
import { RoutesEnum } from './enums';
import { Composers } from './routes/Composers';
import { Tracks } from './routes/Tracks';
import Impersonate from './routes/Impersonate';
import Submissions from './routes/Submissions';

function RequireAuth({ component }: { component: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.isAuthenticated) {
    console.log(
      `App: authenticated: ${auth.isAuthenticated}, location: ${location.pathname}`
    );
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  return component;
}

function RequireOnboarding({ component }: { component: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.user && !auth.user?.onboarded) {
    return <Navigate to='/onboarding' state={{ from: location }} replace />;
  }

  return component;
}

init({
  dsn: SENTRY_DSN,
  release: RELEASE_VERSION,
  tracesSampleRate: 1.0,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Breadcrumbs({
      console: false,
    }),
  ],
});

function App() {
  const [searchParams] = useSearchParams();
  const reset = searchParams.get('reset');
  const backend = searchParams.get('backend');

  useEffect(() => {
    if (reset !== null) {
      localStorage.clear();
    }
    if (backend !== null) {
      console.log('setting backend', backend);
      // configure application to use alternative backend
      localStorage.setItem('backend', backend);
      const url = new URL(window.location.href);
      url.searchParams.delete('backend');
      window.history.pushState({}, '', url);
      window.location.reload();
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <ErrorBoundary
        FallbackComponent={({ error }) => <MajorError error={error} />}
        onReset={() => console.log('reset')}
        // resetKeys={[explode]}
      >
        <AuthProvider>
          <GlobalStateProvider>
            {/* <PlayerProvider> */}
            <Routes>
              <Route path='/' element={<StudioLayout />}>
                {/* public  */}
                <Route path='/login' element={<Login />} />
                <Route path='/resetPassword' element={<PasswordReset />} />
                <Route
                  path='/resetPassword/:token'
                  element={<PasswordResetExecute />}
                />
                <Route path='/signup' element={<SignUp />} />
                <Route
                  path='/oauth/:providerId'
                  element={<OAuthVerification />}
                />
                {/* private  */}
                <Route
                  path='/onboarding'
                  element={<RequireAuth component={<Onboarding />} />}
                />
                <Route
                  path='/impersonate'
                  element={<RequireAuth component={<Impersonate />} />}
                />
                <Route
                  path='/tags'
                  element={<RequireAuth component={<TagsRoute />} />}
                />
                <Route
                  path={RoutesEnum.Submissions}
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<Submissions />} />
                      }
                    />
                  }
                />
                <Route
                  path={RoutesEnum.Albums}
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<AlbumDashboard />} />
                      }
                    />
                  }
                />
                <Route
                  path='/composers'
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<Composers />} />
                      }
                    />
                  }
                />
                <Route
                  path='/artists'
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<ArtistsDashboard />} />
                      }
                    />
                  }
                />
                <Route
                  path='/tracks'
                  element={
                    <RequireAuth
                      component={<RequireOnboarding component={<Tracks />} />}
                    />
                  }
                />
                {/* <Route
                  path="/projects"
                  element={
                    <RequireAuth
                      component={<RequireOnboarding component={<Projects />} />}
                    />
                  }
                /> */}
                <Route
                  path='/checkout/:status'
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<CheckoutCallback />} />
                      }
                    />
                  }
                />
                <Route
                  path='/submission/:id'
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<ProjectStudioView />} />
                      }
                    />
                  }
                />
                <Route
                  path='/submission/:id/:targetId'
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<ProjectStudioView />} />
                      }
                    />
                  }
                />
                <Route path='/album/:id' element={<AlbumStudioView />} />
                <Route
                  path='/album/:id/:targetId'
                  element={<AlbumStudioView />}
                />
                {/* <Route
                  path="/submission/:id/join"
                  element={<ProjectAccessRequest />}
                /> */}
                <Route
                  path='/team'
                  element={
                    <RequireAuth
                      component={<RequireOnboarding component={<Team />} />}
                    />
                  }
                />
                <Route
                  path={RoutesEnum.Dashboard}
                  element={
                    <RequireAuth
                      component={
                        <RequireOnboarding component={<Dashboard />} />
                      }
                    />
                  }
                />
                <Route path='*' element={<NotFound />} />
              </Route>
            </Routes>
            {/* </PlayerProvider> */}
          </GlobalStateProvider>
        </AuthProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
