import { DateTime } from 'luxon';
import { IComposer } from '../services/composer.service';
import { IProject } from '../services/project.service';
import { ITag } from '../services/tag.service';
import { ITeam } from '../services/team.service';
import { ITrack } from '../services/track.service';

/**
 * This function clusters the tempo into three categories: Slow, Medium, Fast based on the bpm
 * @param bpm - the bpm of the track
 * @returns
 */
function getTempo(bpm: string) {
  const localTempo = parseInt(bpm);
  if (localTempo < 80) {
    return 'Slow';
  } else if (localTempo < 120) {
    return 'Medium';
  } else {
    return 'Fast';
  }
}

/**
 * This function returns the common mapping for all three xml mappers
 * @param team - the team object
 * @param project - the project object
 * @param track - the track object
 * @param composers - the composers array
 * @param tags - the tags array
 * @returns
 */
function getCommonMapping(
  team: ITeam,
  project: IProject,
  track: ITrack,
  composers: IComposer[],
  tags: ITag[]
) {
  const embedder = 'KTR Studio';
  const composerString = composers
    .map(
      (composer) =>
        `${composer.firstName} ${composer.lastName} (${composer.pro} ${composer.ipi})`
    )
    .join(', ');
  const publisherShare = '100%';
  const musicPublisher = `${team.publisherName} (IPI ${team.ipi}) ${publisherShare}`;
  const trackAlbumCode = `${project.metadata.albumCode}_${track.metadata.trackNumber}`;
  const library = `${team.name} ${trackAlbumCode}`;
  const songTitle = `${track.name} - ${team.name}`;

  const tagsMood = tags
    .filter((x) => x.type === 'MOOD')
    .map((tag) => tag.name)
    .join(', ');
  const tagsInstrument = tags
    .filter((x) => x.type === 'INSTRUMENT')
    .map((tag) => tag.name)
    .join(', ');

  const description = `${team.website}, ${team.email} | ${tagsMood} | ${tagsInstrument}`;

  // FIXME: hardcoded
  const keywords =
    'Film, Cinematic, Soundtrack, Sound Design, Fantasy, Emotive, Action, Extreme Sports, Gala, Advertising, Epic, Game';

  return {
    composerString,
    publisherShare,
    musicPublisher,
    library,
    songTitle,
    tagsMood,
    tagsInstrument,
    description,
    trackAlbumCode,
    keywords,
    embedder,
  };
}

// ASWG mapper
function buildIXML_ASWG(
  team: ITeam,
  project: IProject,
  track: ITrack,
  composers: IComposer[],
  tags: ITag[]
) {
  const { composerString, musicPublisher, library, songTitle } =
    getCommonMapping(team, project, track, composers, tags);

  return {
    notes: musicPublisher, //'Audiomode International Publishing (IPI 01134300808) 100%',
    inKey: `${track.metadata.key} ${track.metadata.scaleType}`, // C
    musicPublisher, //'Audiomode International Publishing (IPI 01134300808) 100%',
    composer: composerString, //'Oleksandr  Kucherenko (BMI 833380836)',
    subCategory: project.metadata.subtitle,
    library, //'Trailer Rebel TR076_01',
    isrcId: track.isrc, // 'DEWK52300101',
    songTitle, //'Alive',
    category: track.metadata.catalogue, // 'Film, Cinematic, Trailer',
    tempo: getTempo(track.metadata.bpm), // '80',
  };
}

// STEINBERG mapper
function buildIXML_STEINBERG(
  team: ITeam,
  project: IProject,
  track: ITrack,
  composers: IComposer[],
  tags: ITag[]
) {
  const {
    musicPublisher,
    library,
    songTitle,
    tagsMood,
    tagsInstrument,
    description,
  } = getCommonMapping(team, project, track, composers, tags);

  return {
    ATTR_LIST: [
      {
        TYPE: 'string',
        NAME: 'MediaLibrary',
        VALUE: library,
      },
      {
        TYPE: 'string',
        NAME: 'MediaCategoryPost',
        VALUE: track.metadata.catalogue, // 'Film, Cinematic, Trailer',
      },
      {
        TYPE: 'string',
        NAME: 'MusicalCharacter',
        VALUE: tagsMood,
      },
      {
        TYPE: 'string',
        NAME: 'MediaComment',
        VALUE: description,
      },
      {
        TYPE: 'string',
        NAME: 'MusicalCategory',
        VALUE: project.metadata.subtitle, //'- Uplifting Epic Postive',
      },
      {
        TYPE: 'string',
        NAME: 'MediaPublisher',
        VALUE: musicPublisher, //'Audiomode International Publishing (IPI 01134300808) 100%',
      },
      {
        TYPE: 'string',
        NAME: 'MediaArtist',
        VALUE: team.name, //'Trailer Rebel',
      },
      {
        TYPE: 'string',
        NAME: 'MediaTrackNumber',
        VALUE: track.metadata.trackNumber, //'1',
      },
      {
        TYPE: 'string',
        NAME: 'MediaAlbum',
        VALUE: project.name, //'Spark - (Uplifting Epic Postive)',
      },
      {
        TYPE: 'string',
        NAME: 'SmfSongName',
        VALUE: songTitle, //'Alive',
      },
      {
        TYPE: 'string',
        NAME: 'MusicalInstrument',
        VALUE: tagsInstrument,
      },
    ],
  };
}

// USER mapper
function buildIXML_USER(
  team: ITeam,
  project: IProject,
  track: ITrack,
  composers: IComposer[],
  tags: ITag[]
) {
  const {
    composerString,
    musicPublisher,
    library,
    songTitle,
    description,
    trackAlbumCode,
    keywords,
    embedder,
  } = getCommonMapping(team, project, track, composers, tags);

  return {
    CDTITLE: `${project.name} (${project.metadata.subtitle})`, //"Spark - (Uplifting Epic Postive)",
    LIBRARY: library, //"Trailer Rebel TR076_01",
    DESCRIPTION: description,
    TRACKTITLE: songTitle, // Alive
    EMBEDDER: embedder, // "KTR Studio",
    ARTIST: team.name, //"Trailer Rebel",
    RELEASEDATE: project.metadata.releaseDate
      ? DateTime.fromISO(project.metadata.releaseDate).toFormat('yyyy-MM-dd')
      : '',
    SOURCE: trackAlbumCode, //"TR076_01",
    BPM: track.metadata.bpm, //"80",
    TRACK: '', //"1",
    KEYWORDS: keywords,
    URL: team.website, //"https://www.trailerrebel.com/",
    VOLUME: project.metadata.albumCode, //"TR076",
    NOTES: musicPublisher, // "Audiomode International Publishing (IPI 01134300808) 100%",
    SUBCATEGORY: project.metadata.subtitle, // "- Uplifting Epic Postive",
    COMPOSER: composerString, // "Oleksandr  Kucherenko (BMI 833380836)",
    PUBLISHER: musicPublisher, // "Audiomode International Publishing (IPI 01134300808) 100%",
    FXNAME: '',
    RATING: '',
    CATEGORY: track.metadata.catalogue,
  };
}

/**
 * This function builds the iXML object
 * @param team - the team object
 * @param project - the project object
 * @param track - the track object
 * @param composers - the composers array
 * @param tags - the tags array
 * @returns
 */
export function buildIXML(
  team: ITeam,
  project: IProject,
  track: ITrack,
  composers: IComposer[],
  tags: ITag[]
) {
  const ASWG = buildIXML_ASWG(team, project, track, composers, tags);
  const STEINBERG = buildIXML_STEINBERG(team, project, track, composers, tags);
  const USER = buildIXML_USER(team, project, track, composers, tags);
  const IXML_VERSION = '1.61';
  return {
    BWFXML: {
      IXML_VERSION,
      ASWG,
      STEINBERG,
      USER,
    },
  };
}
